import React, { useEffect, useState } from "react";
import Header from "../Header";
import Footer from "../Footer";
import { useDispatch, useSelector } from "react-redux";
import { fetchWishlist } from "../../reduxToolKit/slices/mySlice/myWishlistSlice";
import StarRatings from "react-star-ratings";
import { Link } from "react-router-dom";

const WishList = () => {
  const { wishlistData } = useSelector((state) => state.myWislistReducer);

  const [type, setType] = useState("ALL");
  const [search, setSearch] = useState("");

  const [readMore, setReadMore] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchWishlist({ type }));
  }, []);

  const handleSearh = (e) => {
    dispatch(fetchWishlist({ type, search: e.target.value }));

    setSearch(e.target.value);
  };
  return (
    <>
      <Header />

      <section>
        <div class="searching">
          <div class="row">
            <div class="col-sm-3">
              <div class="FilterArea">
                <h2>My Activity</h2>
                <div class="activity-left">
                  <ul>
                    <li>
                    <Link to="/acitvity-contact">
                      <a>Contacted Owners </a>
                      </Link>
                    </li>
                    <li class="active">
                      <Link to="/wish-list">
                        Wishlist Properties{" "}
                        <span>{wishlistData?.data?.length}</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="col-sm-9">
              <div class="right-search-area">
                <div class="activity-search">
                  <div class="activity-search-tab">
                    <div class="CommonTabs">
                      <ul class="nav nav-tabs">
                        <li class="nav-item">
                                        <a class="nav-link active" data-toggle="tab" href="#all">All</a>
                                    </li>
                        <li class="nav-item">
                          <a class="nav-link" data-toggle="tab" href="#pg">
                            PG
                          </a>
                        </li>
                        <li class="nav-item">
                          <a class="nav-link" data-toggle="tab" href="#flat">
                            Flat
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div class="search-available">
                      <div class="form-group">
                        <input
                          type="search"
                          class="form-control"
                          onChange={handleSearh}
                          value={search}
                          placeholder="Search Address, City, ZIP code"
                        />
                      </div>
                      <div class="Actions">
                        Only Available
                        <label class="switch">
                          <input type="checkbox" />
                          <span class="slider"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="tab-content">
                    <div class="tab-pane fade active show" id="all">
                      {wishlistData?.data?.map((item, i) =>
                        item?.type == "PG" ? (
                          <div class="search-body">
                            <div class="search-verified">
                              {item?.property?.[0]?.verified && (
                                <span class="green-verfied">
                                  <img
                                    src={require("../../assets/images/verified.png")}
                                    alt=""
                                  />{" "}
                                  Verified
                                </span>
                              )}
                              <span class="boy">
                                {item?.property?.[0]?.availableFor}
                              </span>
                              <div class="left-search-verified">
                                <img
                                  src={
                                    item?.property?.[0]?.propertyMedia?.length >
                                    0
                                      ? item?.property?.[0]?.propertyMedia?.[0]
                                          ?.mediaUrl
                                      : require("../../assets/images/search1.png")
                                  }
                                  alt=""
                                />
                              </div>

                              <div class="right-search-verified">
                                <div class="tab-search">
                                  <ul class="PreferredButton">
                                    {item?.property?.[0]?.pgHostelRule
                                      ?.slice(0, 5)
                                      ?.map((rule) => (
                                        <li>{rule}</li>
                                      ))}
                                  </ul>

                                  <ul class="PreferredList">
                                    <li>
                                      {/* <a onClick={() => handleFav(i)}>
                                  {item?.property?.[0]?.isFav ? (
                                    <img
                                      src={require("../../assets/images/heart-red.png")}
                                      alt=""
                                    />
                                  ) : (
                                    <img
                                      src={require("../../assets/images/heart.png")}
                                      alt=""
                                    />
                                  )}
                                </a> */}
                                    </li>
                                    <li>
                                      <a href="#">
                                        <img
                                          src={require("../../assets/images/shere.png")}
                                          alt=""
                                        />
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                                <figcaption>
                                  <h4>{item?.property?.[0]?.name}</h4>
                                  &nbsp; &nbsp;
                                  <StarRatings
                                    rating={item?.property?.[0]?.rating}
                                    starRatedColor="#f89117"
                                    starDimension="15px"
                                    starSpacing="0px"
                                  />
                                  &nbsp;&nbsp;
                                  <p>
                                    {item?.property?.[0]?.rating} (
                                    {item?.property?.[0]?.ratings?.length}{" "}
                                    Reviews)
                                  </p>
                                </figcaption>
                                <h5>
                                  <img
                                    src={require("../../assets/images/location.png")}
                                    alt=""
                                  />
                                  {item?.property?.[0]?.address}{" "}
                                </h5>
                                <ul class="flatOption">
                                  {item?.property?.[0]?.pgRooms?.filter(
                                    (room) => room?.roomType == "Single Bed"
                                  )?.length > 0 && (
                                    <li>
                                      <p>Single Room </p>
                                      <h6>
                                        Rs.{" "}
                                        {
                                          item?.property?.[0]?.pgRooms?.filter(
                                            (room) =>
                                              room?.roomType == "Single Bed"
                                          )?.[0]?.monthlyRent
                                        }
                                      </h6>
                                    </li>
                                  )}

                                  {item?.property?.[0]?.pgRooms?.filter(
                                    (room) => room?.roomType == "Double Bed"
                                  )?.length > 0 && (
                                    <li>
                                      <p>Double Sharing </p>
                                      <h6>
                                        Rs.{" "}
                                        {
                                          item?.property?.[0]?.pgRooms?.filter(
                                            (room) =>
                                              room?.roomType == "Double Bed"
                                          )?.[0]?.monthlyRent
                                        }
                                      </h6>
                                    </li>
                                  )}
                                  {item?.property?.[0]?.pgRooms?.filter(
                                    (room) => room?.roomType == "Triple Bed"
                                  )?.length > 0 && (
                                    <li>
                                      <p>Triple Sharing </p>
                                      <h6>
                                        Rs.{" "}
                                        {
                                          item?.property?.[0]?.pgRooms?.filter(
                                            (room) =>
                                              room?.roomType == "Triple Bed"
                                          )?.[0]?.monthlyRent
                                        }
                                      </h6>
                                    </li>
                                  )}

                                  {item?.property?.[0]?.pgRooms?.filter(
                                    (room) => room?.roomType == "Four Bed"
                                  )?.length > 0 && (
                                    <li>
                                      <p>SharingFour</p>
                                      <h6>
                                        Rs.{" "}
                                        {
                                          item?.property?.[0]?.pgRooms?.filter(
                                            (room) =>
                                              room?.roomType == "Four Bed"
                                          )?.[0]?.monthlyRent
                                        }
                                      </h6>
                                    </li>
                                  )}
                                </ul>

                                <ul class="facility-area">
                                  <li>
                                    <span>
                                      <img
                                        src={require("../../assets/images/facility-1.png")}
                                      />
                                    </span>
                                  </li>
                                  <li>
                                    <span>
                                      <img
                                        src={require("../../assets/images/facility-2.png")}
                                      />
                                    </span>
                                  </li>
                                  <li>
                                    <span>
                                      <img
                                        src={require("../../assets/images/facility-3.png")}
                                      />
                                    </span>
                                  </li>
                                  <li>
                                    <span>
                                      <img
                                        src={require("../../assets/images/facility-4.png")}
                                      />
                                    </span>
                                  </li>
                                  <li>
                                    <span>
                                      <img
                                        src={require("../../assets/images/facility-5.png")}
                                      />
                                    </span>
                                  </li>
                                  <li>
                                    <span>
                                      <img
                                        src={require("../../assets/images/facility-6.png")}
                                      />
                                    </span>
                                  </li>
                                  <li>
                                    <span>
                                      <img
                                        src={require("../../assets/images/facility-6.png")}
                                      />
                                    </span>
                                  </li>
                                  <li>
                                    <span>
                                      <img
                                        src={require("../../assets/images/facility-6.png")}
                                      />
                                    </span>
                                  </li>
                                  <li>
                                    <span>
                                      <img
                                        src={require("../../assets/images/facility-6.png")}
                                      />
                                    </span>
                                  </li>
                                  <li>
                                    <span>
                                      <img
                                        src={require("../../assets/images/facility-6.png")}
                                      />
                                    </span>
                                  </li>
                                  <li>
                                    <span>
                                      <img
                                        src={require("../../assets/images/facility-6.png")}
                                      />
                                    </span>
                                  </li>
                                  <li>
                                    <small>+ 17 More</small>
                                  </li>
                                </ul>

                                <label>
                                  {readMore
                                    ? item?.property?.[0]?.description
                                    : item?.property?.[0]?.description?.slice(
                                        0,
                                        185
                                      )}{" "}
                                  &nbsp;{" "}
                                  <a
                                    style={{ color: "black" }}
                                    onClick={() => {
                                      readMore
                                        ? setReadMore(false)
                                        : setReadMore(true);
                                    }}
                                  >
                                    {readMore ? "Read Less..." : "Read More..."}
                                  </a>
                                </label>

                                <div class="view-contact">
                                  <Link
                                    class="Button"
                                    to="/pg-details"
                                    state={item}
                                  >
                                    View Contact
                                  </Link>
                                  <Link
                                    to="/pg-details"
                                    state={item}
                                    class="viewbutton"
                                  ></Link>
                                </div>
                              </div>
                            </div>
                            <hr />
                            <div class="OrderDetails">
                              {/* <ul class="OrderDetails-3">
                        {nearPlace?.map((place)=>
                            <li>
                            <a onClick={()=> setMapData({...mapData, place: place.value})} className={place.value==mapData.place?"active":""} >
                              {place.label}
                            </a>
                          </li>)
                        }
                    
                      </ul> */}
                              {/* <!-- <h5>Neighborhood</h5>  --> */}

                              {/* <MapView  mapData={mapData} currentLocation={{lat:item?.property?.[0]?.location?.coordinates?.[1],lng:item?.property?.[0]?.location?.coordinates?.[0]}} /> */}

                              {/* <iframe src={require("../../assets/https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d224345.89796633532!2d77.04416939024959!3d28.527554410910344!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce35b9975b1fb%3A0x69a09f265ef3b22a!2sAkshardham!5e0!3m2!1sen!2sin!4v1714032007397!5m2!1sen!2sin" width="100%" height="350" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
                            </div>
                          </div>
                        ) : (
                          <div class="search-body">
                            <div class="search-verified">
                              {item?.property?.[0]?.verified && (
                                <span class="green-verfied">
                                  <img
                                    src={require("../../assets/images/verified.png")}
                                    alt=""
                                  />{" "}
                                  Verified
                                </span>
                              )}
                              <span class="boy">
                                {item?.property?.[0]?.availableFor}
                              </span>
                              <div class="left-search-verified">
                                <img
                                  src={
                                    item?.property?.[0]?.propertyMedia?.length >
                                    0
                                      ? item?.property?.[0]?.propertyMedia?.[0]
                                          ?.mediaUrl
                                      : require("../../assets/images/search1.png")
                                  }
                                  alt=""
                                />
                              </div>

                              <div class="right-search-verified">
                                <div class="tab-search">
                                  <ul class="PreferredButton">
                                    {item?.property?.[0]?.pgHostelRule
                                      ?.slice(0, 5)
                                      ?.map((rule) => (
                                        <li>{rule}</li>
                                      ))}
                                  </ul>

                                  <ul class="PreferredList">
                                    <li>
                                      {/* <a onClick={() => handleFav(i)}>
                                  {item?.property?.[0]?.isFav ? (
                                    <img
                                      src={require("../../assets/images/heart-red.png")}
                                      alt=""
                                    />
                                  ) : (
                                    <img
                                      src={require("../../assets/images/heart.png")}
                                      alt=""
                                    />
                                  )}
                                </a> */}
                                    </li>
                                    <li>
                                      <a href="#">
                                        <img
                                          src={require("../../assets/images/shere.png")}
                                          alt=""
                                        />
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                                <figcaption>
                                  <h4>{item?.property?.[0]?.name}</h4>
                                  &nbsp; &nbsp;
                                  <StarRatings
                                    rating={item?.property?.[0]?.rating}
                                    starRatedColor="#f89117"
                                    starDimension="15px"
                                    starSpacing="0px"
                                  />
                                  &nbsp;&nbsp;
                                  <p>
                                    {item?.property?.[0]?.rating} (
                                    {item?.property?.[0]?.ratings?.length}{" "}
                                    Reviews)
                                  </p>
                                </figcaption>
                                <h5>
                                  <img
                                    src={require("../../assets/images/location.png")}
                                    alt=""
                                  />
                                  {item?.property?.[0]?.address}{" "}
                                </h5>

                                <h6>
                                  ₹ {item?.property?.[0]?.expectedRent}{" "}
                                  <img
                                    src={require("../../assets/images/impot.png")}
                                    alt=""
                                  />
                                </h6>

                                <ul class="flatOption">
                                  <li>
                                    <p>Furnishing</p>
                                    <h6>{item?.property?.[0]?.furnishing}</h6>
                                  </li>
                                  <li>
                                    <p>Carpet Area</p>
                                    <h6>
                                      {item?.property?.[0]?.builtUpArea} sqft
                                    </h6>
                                  </li>
                                  <li>
                                    <p>Floor</p>
                                    <h6>
                                      {item?.property?.[0]?.floor} out of{" "}
                                      {item?.property?.[0]?.totalFloor}
                                    </h6>
                                  </li>
                                  <li>
                                    <p>Preferred Tenant</p>
                                    <h6>
                                      {item?.property?.[0]?.preferredTenant?.toString()}{" "}
                                    </h6>
                                  </li>
                                </ul>

                                <ul class="facility-area">
                                  <li>
                                    <span>
                                      <img
                                        src={require("../../assets/images/facility-1.png")}
                                      />
                                    </span>
                                  </li>
                                  <li>
                                    <span>
                                      <img
                                        src={require("../../assets/images/facility-2.png")}
                                      />
                                    </span>
                                  </li>
                                  <li>
                                    <span>
                                      <img
                                        src={require("../../assets/images/facility-3.png")}
                                      />
                                    </span>
                                  </li>
                                  <li>
                                    <span>
                                      <img
                                        src={require("../../assets/images/facility-4.png")}
                                      />
                                    </span>
                                  </li>
                                  <li>
                                    <span>
                                      <img
                                        src={require("../../assets/images/facility-5.png")}
                                      />
                                    </span>
                                  </li>
                                  <li>
                                    <span>
                                      <img
                                        src={require("../../assets/images/facility-6.png")}
                                      />
                                    </span>
                                  </li>
                                  <li>
                                    <span>
                                      <img
                                        src={require("../../assets/images/facility-6.png")}
                                      />
                                    </span>
                                  </li>
                                  <li>
                                    <span>
                                      <img
                                        src={require("../../assets/images/facility-6.png")}
                                      />
                                    </span>
                                  </li>
                                  <li>
                                    <span>
                                      <img
                                        src={require("../../assets/images/facility-6.png")}
                                      />
                                    </span>
                                  </li>
                                  <li>
                                    <span>
                                      <img
                                        src={require("../../assets/images/facility-6.png")}
                                      />
                                    </span>
                                  </li>
                                  <li>
                                    <span>
                                      <img
                                        src={require("../../assets/images/facility-6.png")}
                                      />
                                    </span>
                                  </li>
                                  <li>
                                    <small>+ 17 More</small>
                                  </li>
                                </ul>

                                <label>
                                  {readMore
                                    ? item?.property?.[0]?.description
                                    : item?.property?.[0]?.description?.slice(
                                        0,
                                        185
                                      )}{" "}
                                  &nbsp;{" "}
                                  <a
                                    style={{ color: "black" }}
                                    onClick={() => {
                                      readMore
                                        ? setReadMore(false)
                                        : setReadMore(true);
                                    }}
                                  >
                                    {readMore ? "Read Less..." : "Read More..."}
                                  </a>
                                </label>

                                <div class="view-contact">
                                  <Link
                                    class="Button"
                                    to="/pg-details"
                                    state={item}
                                  >
                                    View Contact
                                  </Link>
                                  <Link
                                    to="/pg-details"
                                    state={item}
                                    class="viewbutton"
                                  ></Link>
                                </div>
                              </div>
                            </div>
                            <hr />
                            <div class="OrderDetails">
                            </div>
                          </div>
                        )
                      )}
                       {wishlistData?.data?.length == 0 && (
                        <div>
                          <img
                            src="https://img.freepik.com/free-vector/no-data-concept-illustration_114360-536.jpg?w=740&t=st=1724762289~exp=1724762889~hmac=1ea735ed1a5357935c2a8703441adb2a16dd5d160ff70884c1ab9273f6b43f0e"
                            style={{
                              display: "block",
                              margin: "auto",
                              width: "45%",
                            }}
                          />
                        </div>
                      )}
                    </div>

                    <div class="tab-pane fade" id="pg">
                      {wishlistData?.data
                        ?.filter((pg) => pg?.type == "PG")
                        ?.map((item, i) => {
                          return (
                            <div class="search-body">
                              <div class="search-verified">
                                {item?.property?.[0]?.verified && (
                                  <span class="green-verfied">
                                    <img
                                      src={require("../../assets/images/verified.png")}
                                      alt=""
                                    />{" "}
                                    Verified
                                  </span>
                                )}
                                <span class="boy">
                                  {item?.property?.[0]?.availableFor}
                                </span>
                                <div class="left-search-verified">
                                  <img
                                    src={
                                      item?.property?.[0]?.propertyMedia
                                        ?.length > 0
                                        ? item?.property?.[0]
                                            ?.propertyMedia?.[0]?.mediaUrl
                                        : require("../../assets/images/search1.png")
                                    }
                                    alt=""
                                  />
                                </div>

                                <div class="right-search-verified">
                                  <div class="tab-search">
                                    <ul class="PreferredButton">
                                      {item?.property?.[0]?.pgHostelRule
                                        ?.slice(0, 5)
                                        ?.map((rule) => (
                                          <li>{rule}</li>
                                        ))}
                                    </ul>

                                    <ul class="PreferredList">
                                      <li>
                                        <a href="#">
                                          <img
                                            src={require("../../assets/images/shere.png")}
                                            alt=""
                                          />
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                  <figcaption>
                                    <h4>{item?.property?.[0]?.name}</h4>
                                    &nbsp; &nbsp;
                                    <StarRatings
                                      rating={item?.property?.[0]?.rating}
                                      starRatedColor="#f89117"
                                      starDimension="15px"
                                      starSpacing="0px"
                                    />
                                    &nbsp;&nbsp;
                                    <p>
                                      {item?.property?.[0]?.rating} (
                                      {item?.property?.[0]?.ratings?.length}{" "}
                                      Reviews)
                                    </p>
                                  </figcaption>
                                  <h5>
                                    <img
                                      src={require("../../assets/images/location.png")}
                                      alt=""
                                    />
                                    {item?.property?.[0]?.address}{" "}
                                  </h5>
                                  <ul class="flatOption">
                                    {item?.property?.[0]?.pgRooms?.filter(
                                      (room) => room?.roomType == "Single Bed"
                                    )?.length > 0 && (
                                      <li>
                                        <p>Single Room </p>
                                        <h6>
                                          Rs.{" "}
                                          {
                                            item?.property?.[0]?.pgRooms?.filter(
                                              (room) =>
                                                room?.roomType == "Single Bed"
                                            )?.[0]?.monthlyRent
                                          }
                                        </h6>
                                      </li>
                                    )}

                                    {item?.property?.[0]?.pgRooms?.filter(
                                      (room) => room?.roomType == "Double Bed"
                                    )?.length > 0 && (
                                      <li>
                                        <p>Double Sharing </p>
                                        <h6>
                                          Rs.{" "}
                                          {
                                            item?.property?.[0]?.pgRooms?.filter(
                                              (room) =>
                                                room?.roomType == "Double Bed"
                                            )?.[0]?.monthlyRent
                                          }
                                        </h6>
                                      </li>
                                    )}
                                    {item?.property?.[0]?.pgRooms?.filter(
                                      (room) => room?.roomType == "Triple Bed"
                                    )?.length > 0 && (
                                      <li>
                                        <p>Triple Sharing </p>
                                        <h6>
                                          Rs.{" "}
                                          {
                                            item?.property?.[0]?.pgRooms?.filter(
                                              (room) =>
                                                room?.roomType == "Triple Bed"
                                            )?.[0]?.monthlyRent
                                          }
                                        </h6>
                                      </li>
                                    )}

                                    {item?.property?.[0]?.pgRooms?.filter(
                                      (room) => room?.roomType == "Four Bed"
                                    )?.length > 0 && (
                                      <li>
                                        <p>SharingFour</p>
                                        <h6>
                                          Rs.{" "}
                                          {
                                            item?.property?.[0]?.pgRooms?.filter(
                                              (room) =>
                                                room?.roomType == "Four Bed"
                                            )?.[0]?.monthlyRent
                                          }
                                        </h6>
                                      </li>
                                    )}
                                  </ul>

                                  <ul class="facility-area">
                                    <li>
                                      <span>
                                        <img
                                          src={require("../../assets/images/facility-1.png")}
                                        />
                                      </span>
                                    </li>
                                    <li>
                                      <span>
                                        <img
                                          src={require("../../assets/images/facility-2.png")}
                                        />
                                      </span>
                                    </li>
                                    <li>
                                      <span>
                                        <img
                                          src={require("../../assets/images/facility-3.png")}
                                        />
                                      </span>
                                    </li>
                                    <li>
                                      <span>
                                        <img
                                          src={require("../../assets/images/facility-4.png")}
                                        />
                                      </span>
                                    </li>
                                    <li>
                                      <span>
                                        <img
                                          src={require("../../assets/images/facility-5.png")}
                                        />
                                      </span>
                                    </li>
                                    <li>
                                      <span>
                                        <img
                                          src={require("../../assets/images/facility-6.png")}
                                        />
                                      </span>
                                    </li>
                                    <li>
                                      <span>
                                        <img
                                          src={require("../../assets/images/facility-6.png")}
                                        />
                                      </span>
                                    </li>
                                    <li>
                                      <span>
                                        <img
                                          src={require("../../assets/images/facility-6.png")}
                                        />
                                      </span>
                                    </li>
                                    <li>
                                      <span>
                                        <img
                                          src={require("../../assets/images/facility-6.png")}
                                        />
                                      </span>
                                    </li>
                                    <li>
                                      <span>
                                        <img
                                          src={require("../../assets/images/facility-6.png")}
                                        />
                                      </span>
                                    </li>
                                    <li>
                                      <span>
                                        <img
                                          src={require("../../assets/images/facility-6.png")}
                                        />
                                      </span>
                                    </li>
                                    <li>
                                      <small>+ 17 More</small>
                                    </li>
                                  </ul>

                                  <label>
                                    {readMore
                                      ? item?.property?.[0]?.description
                                      : item?.property?.[0]?.description?.slice(
                                          0,
                                          185
                                        )}{" "}
                                    &nbsp;{" "}
                                    <a
                                      style={{ color: "black" }}
                                      onClick={() => {
                                        readMore
                                          ? setReadMore(false)
                                          : setReadMore(true);
                                      }}
                                    >
                                      {readMore
                                        ? "Read Less..."
                                        : "Read More..."}
                                    </a>
                                  </label>

                                  <div class="view-contact">
                                    <Link
                                      class="Button"
                                      to="/pg-details"
                                      state={item}
                                    >
                                      View Contact
                                    </Link>
                                    <Link
                                      to="/pg-details"
                                      state={item}
                                      class="viewbutton"
                                    ></Link>
                                  </div>
                                </div>
                              </div>
                              <hr />
                              <div class="OrderDetails">
                                {/* <ul class="OrderDetails-3">
                        {nearPlace?.map((place)=>
                            <li>
                            <a onClick={()=> setMapData({...mapData, place: place.value})} className={place.value==mapData.place?"active":""} >
                              {place.label}
                            </a>
                          </li>)
                        }
                    
                      </ul> */}
                                {/* <!-- <h5>Neighborhood</h5>  --> */}

                                {/* <MapView  mapData={mapData} currentLocation={{lat:item?.property?.[0]?.location?.coordinates?.[1],lng:item?.property?.[0]?.location?.coordinates?.[0]}} /> */}

                                {/* <iframe src={require("../../assets/https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d224345.89796633532!2d77.04416939024959!3d28.527554410910344!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce35b9975b1fb%3A0x69a09f265ef3b22a!2sAkshardham!5e0!3m2!1sen!2sin!4v1714032007397!5m2!1sen!2sin" width="100%" height="350" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
                              </div>
                            </div>
                          );
                        })}
                      {wishlistData?.data?.length == 0 && (
                        <div>
                          <img
                            src="https://img.freepik.com/free-vector/no-data-concept-illustration_114360-536.jpg?w=740&t=st=1724762289~exp=1724762889~hmac=1ea735ed1a5357935c2a8703441adb2a16dd5d160ff70884c1ab9273f6b43f0e"
                            style={{
                              display: "block",
                              margin: "auto",
                              width: "45%",
                            }}
                          />
                        </div>
                      )}
                    </div>

                    <div class="tab-pane fade" id="flat">
                      {wishlistData?.data
                        ?.filter((flat) => flat?.type == "FLAT")
                        ?.map((item, i) => {
                          return (
                            <div class="search-body">
                              <div class="search-verified">
                                {item?.property?.[0]?.verified && (
                                  <span class="green-verfied">
                                    <img
                                      src={require("../../assets/images/verified.png")}
                                      alt=""
                                    />{" "}
                                    Verified
                                  </span>
                                )}
                                <span class="boy">
                                  {item?.property?.[0]?.availableFor}
                                </span>
                                <div class="left-search-verified">
                                  <img
                                    src={
                                      item?.property?.[0]?.propertyMedia
                                        ?.length > 0
                                        ? item?.property?.[0]
                                            ?.propertyMedia?.[0]?.mediaUrl
                                        : require("../../assets/images/search1.png")
                                    }
                                    alt=""
                                  />
                                </div>

                                <div class="right-search-verified">
                                  <div class="tab-search">
                                    <ul class="PreferredButton">
                                      {item?.property?.[0]?.pgHostelRule
                                        ?.slice(0, 5)
                                        ?.map((rule) => (
                                          <li>{rule}</li>
                                        ))}
                                    </ul>

                                    <ul class="PreferredList">
                                      <li>
                                        {/* <a onClick={() => handleFav(i)}>
                                  {item?.property?.[0]?.isFav ? (
                                    <img
                                      src={require("../../assets/images/heart-red.png")}
                                      alt=""
                                    />
                                  ) : (
                                    <img
                                      src={require("../../assets/images/heart.png")}
                                      alt=""
                                    />
                                  )}
                                </a> */}
                                      </li>
                                      <li>
                                        <a href="#">
                                          <img
                                            src={require("../../assets/images/shere.png")}
                                            alt=""
                                          />
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                  <figcaption>
                                    <h4>{item?.property?.[0]?.name}</h4>
                                    &nbsp; &nbsp;
                                    <StarRatings
                                      rating={item?.property?.[0]?.rating}
                                      starRatedColor="#f89117"
                                      starDimension="15px"
                                      starSpacing="0px"
                                    />
                                    &nbsp;&nbsp;
                                    <p>
                                      {item?.property?.[0]?.rating} (
                                      {item?.property?.[0]?.ratings?.length}{" "}
                                      Reviews)
                                    </p>
                                  </figcaption>
                                  <h5>
                                    <img
                                      src={require("../../assets/images/location.png")}
                                      alt=""
                                    />
                                    {item?.property?.[0]?.address}{" "}
                                  </h5>

                                  <h6>
                                    ₹ {item?.property?.[0]?.expectedRent}{" "}
                                    <img
                                      src={require("../../assets/images/impot.png")}
                                      alt=""
                                    />
                                  </h6>

                                  <ul class="flatOption">
                                    <li>
                                      <p>Furnishing</p>
                                      <h6>{item?.property?.[0]?.furnishing}</h6>
                                    </li>
                                    <li>
                                      <p>Carpet Area</p>
                                      <h6>
                                        {item?.property?.[0]?.builtUpArea} sqft
                                      </h6>
                                    </li>
                                    <li>
                                      <p>Floor</p>
                                      <h6>
                                        {item?.property?.[0]?.floor} out of{" "}
                                        {item?.property?.[0]?.totalFloor}
                                      </h6>
                                    </li>
                                    <li>
                                      <p>Preferred Tenant</p>
                                      <h6>
                                        {item?.property?.[0]?.preferredTenant?.toString()}{" "}
                                      </h6>
                                    </li>
                                  </ul>

                                  <ul class="facility-area">
                                    <li>
                                      <span>
                                        <img
                                          src={require("../../assets/images/facility-1.png")}
                                        />
                                      </span>
                                    </li>
                                    <li>
                                      <span>
                                        <img
                                          src={require("../../assets/images/facility-2.png")}
                                        />
                                      </span>
                                    </li>
                                    <li>
                                      <span>
                                        <img
                                          src={require("../../assets/images/facility-3.png")}
                                        />
                                      </span>
                                    </li>
                                    <li>
                                      <span>
                                        <img
                                          src={require("../../assets/images/facility-4.png")}
                                        />
                                      </span>
                                    </li>
                                    <li>
                                      <span>
                                        <img
                                          src={require("../../assets/images/facility-5.png")}
                                        />
                                      </span>
                                    </li>
                                    <li>
                                      <span>
                                        <img
                                          src={require("../../assets/images/facility-6.png")}
                                        />
                                      </span>
                                    </li>
                                    <li>
                                      <span>
                                        <img
                                          src={require("../../assets/images/facility-6.png")}
                                        />
                                      </span>
                                    </li>
                                    <li>
                                      <span>
                                        <img
                                          src={require("../../assets/images/facility-6.png")}
                                        />
                                      </span>
                                    </li>
                                    <li>
                                      <span>
                                        <img
                                          src={require("../../assets/images/facility-6.png")}
                                        />
                                      </span>
                                    </li>
                                    <li>
                                      <span>
                                        <img
                                          src={require("../../assets/images/facility-6.png")}
                                        />
                                      </span>
                                    </li>
                                    <li>
                                      <span>
                                        <img
                                          src={require("../../assets/images/facility-6.png")}
                                        />
                                      </span>
                                    </li>
                                    <li>
                                      <small>+ 17 More</small>
                                    </li>
                                  </ul>

                                  <label>
                                    {readMore
                                      ? item?.property?.[0]?.description
                                      : item?.property?.[0]?.description?.slice(
                                          0,
                                          185
                                        )}{" "}
                                    &nbsp;{" "}
                                    <a
                                      style={{ color: "black" }}
                                      onClick={() => {
                                        readMore
                                          ? setReadMore(false)
                                          : setReadMore(true);
                                      }}
                                    >
                                      {readMore
                                        ? "Read Less..."
                                        : "Read More..."}
                                    </a>
                                  </label>

                                  <div class="view-contact">
                                    <Link
                                      class="Button"
                                      to="/pg-details"
                                      state={item}
                                    >
                                      View Contact
                                    </Link>
                                    <Link
                                      to="/pg-details"
                                      state={item}
                                      class="viewbutton"
                                    ></Link>
                                  </div>
                                </div>
                              </div>
                              <hr />
                              <div class="OrderDetails">
                                {/* <ul class="OrderDetails-3">
                        {nearPlace?.map((place)=>
                            <li>
                            <a onClick={()=> setMapData({...mapData, place: place.value})} className={place.value==mapData.place?"active":""} >
                              {place.label}
                            </a>
                          </li>)
                        }
                    
                      </ul> */}
                                {/* <!-- <h5>Neighborhood</h5>  --> */}

                                {/* <MapView  mapData={mapData} currentLocation={{lat:item?.property?.[0]?.location?.coordinates?.[1],lng:item?.property?.[0]?.location?.coordinates?.[0]}} /> */}

                                {/* <iframe src={require("../../assets/https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d224345.89796633532!2d77.04416939024959!3d28.527554410910344!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce35b9975b1fb%3A0x69a09f265ef3b22a!2sAkshardham!5e0!3m2!1sen!2sin!4v1714032007397!5m2!1sen!2sin" width="100%" height="350" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
                              </div>
                            </div>
                          );
                        })}
                      {wishlistData?.data?.length == 0 && (
                        <div>
                          <img
                            src="https://img.freepik.com/free-vector/no-data-concept-illustration_114360-536.jpg?w=740&t=st=1724762289~exp=1724762889~hmac=1ea735ed1a5357935c2a8703441adb2a16dd5d160ff70884c1ab9273f6b43f0e"
                            style={{
                              display: "block",
                              margin: "auto",
                              width: "45%",
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default WishList;
