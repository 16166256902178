import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { fetchProfile } from "../reduxToolKit/slices/authSlice.js/getProfileSlice";
import { isLoggedIn } from "../utils";
import { FaUser } from "react-icons/fa";
import { HiBuildingOffice2 } from "react-icons/hi2";

const Header = () => {

  const dispatch = useDispatch()

  const {profileData} = useSelector((state)=>state.profileReducer)


  const [currentLocation, setCurrentLocation] = useState("");

  const navigate = useNavigate()

  

  const staticAddress ="Gurugram"

  const fetchAddress = (lat, lng) => {
    fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyBzk1iAqappPnU_19zmEz4_9A7z8DrP8Yo`
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        let tempFormattedAddress = data?.results?.[0]?.formatted_address ?? "";
        // console.log(data?.results, "tempAddress")
        setCurrentLocation(tempFormattedAddress);
      });
  };

  const autoLocationHandler = () => {
    navigator.geolocation.getCurrentPosition((position, error) => {
      if (position) {
        fetchAddress(position?.coords?.latitude, position?.coords?.longitude);
      }
    });
  };

  useEffect(() => {
    autoLocationHandler();
  }, []);

  useEffect(()=>{
    if(isLoggedIn("pgUser")){
        dispatch(fetchProfile())
    }

  },[])

  const handleLogout=()=>{
    window.localStorage.removeItem('pgUser');
    
    navigate('/')
    
  }


  return (
    <header>
      <div class="header">
        <nav class="navbar navbar-expand-lg navbar-light">
          {/* <figure><img src={require("../assets/images/pg-4.png")} /></figure> */}
          <Link to="/" class="navbar-brand">
            <img src={require("../assets/images/logo.png")} />
          </Link>
          <ul class="location-area">
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle arrow-img"
                id="navbarDropdownMenuLink"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {currentLocation?currentLocation?.slice(0,15):staticAddress} ...
              </a>
            </li>
          </ul>

          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>

          <div
            class="collapse navbar-collapse property-nav"
            id="navbarSupportedContent"
          >
            <form class="form-inline my-2 my-lg-0">
              <figure>
                <img src={require("../assets/images/android.png")} alt=""  style={{width:"100px"}}/>
                <img src={require("../assets/images/ios.png")} alt=""  style={{width:"100px"}}/>
              </figure>
              <div class="Main-User-Left">
                {isLoggedIn("pgUser") ? (
                  <a class="sign-in">
                    <span className={profileData?.data?.profileImage?"prof-img":""}>
                      <img src={profileData?.data?.profileImage?profileData?.data?.profileImage: require("../assets/images/user.png")} alt="" />
                    </span>
                    {profileData?.data?.name}
                  </a>
                ) : (
                  <Link to="/login" class="sign-in">
                    <span>
                      {/* <img src={require("../assets/images/user.png")} alt="" /> */}
                      <FaUser style={{fontSize:"22px", color:"#FD701E"}}/>
                    </span>
                    Sign in
                  </Link>
                )}
                  {isLoggedIn("pgUser") && (

                <ul>
                    <li>
                      <Link to="/my-profile">My Profile</Link>
                    </li>
                  <li>
                    <div class="dropdown">
                      <a
                        class="dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        My Activities
                      </a>
                      <div
                        class="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <Link to="/acitvity-contact" class="dropdown-item" >
                          Contacted Owner
                        </Link>
                        <Link class="dropdown-item" to="/wish-list">
                          Wishlist Properties
                        </Link>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="dropdown">
                      <a
                        class="dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        My Properties
                      </a>
                      <div
                        class="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <Link class="dropdown-item" to="/property-listing">
                        Listing
                        </Link>
                      
                      </div>
                    </div>
                  </li>
                  <li>
                    <a href="subscriptions.html">My Subscription</a>
                  </li>
                  <li>
                    <div class="dropdown">
                      <a
                        class="dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Help
                      </a>
                      <div
                        class="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <a class="dropdown-item" href="help-contact.html">
                          Contact Us{" "}
                        </a>
                        <Link class="dropdown-item" to="/help-support">
                          Request a call Back
                        </Link>
                      </div>
                    </div>
                  </li>
                  <li>
                    <a onClick={handleLogout}>Logout</a>
                  </li>
                  {!isLoggedIn("pgUser") && (
                    <li>
                      <Link class="login" to="/login">
                        Login
                      </Link>
                    </li>
                  )}
                </ul>                  )}

              </div>

              
                <Link to={isLoggedIn("pgUser")?"/sell":"/login"} class="button">
                  <span>
                    {/* <img
                      src={require("../assets/images/building.png")}
                      alt=""
                    /> */}
                    <HiBuildingOffice2 style={{fontSize:"32px", color:"#fff"}}/>
                  </span>{" "}
                  Post your property
                </Link>
              
            </form>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
