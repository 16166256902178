import React, { useState ,useEffect} from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Link, useNavigate } from "react-router-dom";
import Lift from "../assets/images/cupBoard.png";
import Intercom from "../assets/images/heating.png";
import Swimming from "../assets/images/matress.png";
import ClubHouse from "../assets/images/acHeating.png";
import { createFlatApi } from "../reduxToolKit/slices/flatSlice.js/addFlatSlice";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { fetchFlatDetails } from "../reduxToolKit/slices/flatSlice.js/flatDetailsSilce";
import { PiSwimmingPoolBold } from "react-icons/pi";
import { GiEscalator } from "react-icons/gi";
import { FaHouseUser } from "react-icons/fa";
import { BsTelephoneFill } from "react-icons/bs";
import { MdBedroomChild } from "react-icons/md";
import { FaCartShopping } from "react-icons/fa6";
import { PiPipeLight } from "react-icons/pi";
import { FaParking } from "react-icons/fa";
import { GiFruitTree } from "react-icons/gi";

const initialState = {
  availableAmenities: [],
  gym: false,
  nonVegAllowed: false,
  gatedSociety: false,
  bathrooms: "",
  balcony: "",
  errorMsg: {},
};

const amenitiesArray = [
  { name: "Lift", img: <GiEscalator style={{color:"#FD701E",fontSize:"22px"}}/> },
  { name: "Intercom", img: <BsTelephoneFill style={{color:"#FD701E",fontSize:"22px"}}/> },
  { name: "Swimming Pool", img: <PiSwimmingPoolBold style={{color:"#FD701E",fontSize:"22px"}}/> },
  { name: "Club House", img: <FaHouseUser style={{color:"#FD701E",fontSize:"22px"}}/> },
  { name: "Servent Room", img: <MdBedroomChild style={{color:"#FD701E",fontSize:"22px"}}/> },
  { name: "Gas Pipeline", img: <PiPipeLight style={{color:"#FD701E",fontSize:"22px"}}/> },
  { name: "Park", img: <GiFruitTree style={{color:"#FD701E",fontSize:"22px"}}/> },
  { name: "Shopping Center", img: <FaCartShopping style={{color:"#FD701E",fontSize:"22px"}}/> },
  { name: "Free Parking", img: <FaParking style={{color:"#FD701E",fontSize:"22px"}}/> },

];

const FlatProperty4 = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { flatDetails } = useSelector((state) => state.flatData);


  const [iState, updateState] = useState(initialState);
  const {
    availableAmenities,
    gym,
    nonVegAllowed,
    gatedSociety,
    bathrooms,
    balcony,
    errorMsg,
  } = iState;

  const handleCheckbox = (e, getter, prop) => {
    const { checked, name } = e.target;

    if (checked) {
      updateState({ ...iState, [name]: [...getter, prop] });
    } else {
      let tempArray = getter;
      let index = tempArray.findIndex((item) => item == prop);
      tempArray.splice(index, 1);

      updateState({ ...iState, [name]: tempArray });
    }
  };

  const handleRadio = (e, setter, prop) => {
    updateState({ ...iState, [setter]: prop });
  };

  const handleValidation = () => {
    let flag = true;
    let errorMsg = {};

    if (availableAmenities?.length == 0) {
      flag = false;
      errorMsg.availableAmenities = "Amenities  required";
    }

    if (!bathrooms) {
      flag = false;
      errorMsg.bathrooms = "Bathrooms  field is required";
    }

    if (!balcony) {
      flag = false;
      errorMsg.balcony = "Balcony field is required";
    }

    updateState({ ...iState, errorMsg });

    return flag;
  };

  const handleSubmitFlat = (e) => {
    e.preventDefault();

    let isValid = handleValidation();
    if (isValid) {
      let data = {
        availableAmenities,
        gym,
        nonVegAllowed,
        gatedSociety,
        bathrooms,
        balcony,
      };
      dispatch(createFlatApi(data))
        .then((res) => {
          if (res.payload.success) {
              navigate("/flat-property5");

            toast.success(res.message);
          } else {
            toast.error(res.message);
          }
        })
        .catch((err) => {});
    }
  };
  useEffect(() => {
    dispatch(fetchFlatDetails());
  }, []);


  useEffect(() => {
    updateState({
      ...iState,

  


      availableAmenities: flatDetails?.data?.[0]?.availableAmenities?flatDetails?.data?.[0]?.availableAmenities:[],
      gym: flatDetails?.data?.[0]?.gym?flatDetails?.data?.[0]?.gym:false,
      nonVegAllowed: flatDetails?.data?.[0]?.nonVegAllowed?flatDetails?.data?.[0]?.nonVegAllowed:false,
      gatedSociety: flatDetails?.data?.[0]?.gatedSociety?flatDetails?.data?.[0]?.gatedSociety:false,
      bathrooms: flatDetails?.data?.[0]?.bathrooms?flatDetails?.data?.[0]?.bathrooms:"",
      balcony: flatDetails?.data?.[0]?.balcony?flatDetails?.data?.[0]?.balcony:"",
     

    });
  }, [flatDetails]);


  return (
    <>
      <Header />
      <ToastContainer />


      <section>
        <div class="steps-details">
          <div class="StepBox">
            <ul>
              <li class="Previous">
                Step 1 <span>Property Details</span>
                <h6 class="Complete">
                  Complete{" "}
                  <span>
                    <i class="fa-solid fa-check"></i>
                  </span>
                </h6>
              </li>
              <li class="Previous">
                Step 2 <span>Location Details</span>
                <h6 class="Complete">
                  Complete{" "}
                  <span>
                    <i class="fa-solid fa-check"></i>
                  </span>{" "}
                </h6>{" "}
              </li>
              <li class="Previous">
                Step 3 <span>Rental Details</span>{" "}
              </li>
              <li class="active">
                Step 4 <span>Amenities</span>{" "}
              </li>
              <li>
                Step 5 <span>Gallery</span>{" "}
              </li>
              <li>
                Step 6 <span>Schedule</span>{" "}
              </li>
            </ul>
          </div>
          <div class="EditPropertyBox">
            <h3 class="Title">Available Services</h3>
            <div class="EditPropertyForm">
              <form>
                <div class="row">
                  <div class="col-lg-4">
                    <div class="form-group">
                      <h6>Gym</h6>
                      <ul>
                        <li>
                          <label class="Radio">
                            {" "}
                            Yes
                            <input
                              type="radio"
                              checked={gym}
                              name="gym"
                              onChange={(e) => handleRadio(e, "gym", true)}
                            />
                            <span class="checkmark"></span>
                          </label>
                        </li>
                        <li>
                          <label class="Radio">
                            {" "}
                            No
                            <input
                              type="radio"
                              checked={!gym}
                              name="gym"
                              onChange={(e) => handleRadio(e, "gym", false)}
                            />{" "}
                            <span class="checkmark"></span>
                          </label>
                        </li>
                      </ul>
                    </div>
                    {/* <div class="form-group">
                      <h6>Gated Society</h6>
                      <ul>
                        <li>
                          <label class="Radio">
                            {" "}
                            Yes
                            <input
                              type="radio"
                              checked={gatedSociety}
                              name="gatedSociety"
                              onChange={(e) =>
                                handleRadio(e, "gatedSociety", true)
                              }
                            />{" "}
                            <span class="checkmark"></span>
                          </label>
                        </li>
                        <li>
                          <label class="Radio">
                            {" "}
                            No
                            <input
                              type="radio"
                              checked={!gatedSociety}
                              name="gatedSociety"
                              onChange={(e) =>
                                handleRadio(e, "gatedSociety", false)
                              }
                            />{" "}
                            <span class="checkmark"></span>
                          </label>
                        </li>
                      </ul>
                    </div> */}
                  </div>
                  <div class="col-lg-4">
                    <div class="form-group">
                      <h6>Non -Veg Allowed </h6>
                      <ul>
                        <li>
                          <label class="Radio">
                            {" "}
                            Yes
                            <input
                              type="radio"
                              checked={nonVegAllowed}
                              name="nonVegAllowed"
                              onChange={(e) =>
                                handleRadio(e, "nonVegAllowed", true)
                              }
                            />{" "}
                            <span class="checkmark"></span>
                          </label>
                        </li>
                        <li>
                          <label class="Radio">
                            {" "}
                            No
                            <input
                              type="radio"
                              checked={!nonVegAllowed}
                              name="nonVegAllowed"
                              onChange={(e) =>
                                handleRadio(e, "nonVegAllowed", false)
                              }
                            />{" "}
                            <span class="checkmark"></span>
                          </label>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="form-group">
                      <h6>Select Available Amenities for your property</h6>
                      <ul class="Extra">
                        {amenitiesArray?.map((item) => (
                          <li>
                            <label class="CheckBox">
                              {" "}
                              {item?.img}  <br /> {item?.name}
                              <input
                                checked={availableAmenities?.includes(
                                  item?.name
                                )}
                                type="checkbox"
                                name="availableAmenities"
                                onChange={(e) =>
                                  handleCheckbox(
                                    e,
                                    availableAmenities,
                                    item?.name
                                  )
                                }
                              />
                              <span class="checkmark"></span>
                            </label>
                          </li>
                        ))}
                      </ul>

                      {errorMsg.availableAmenities  && availableAmenities?.length ==0&& (
                        <p className="error-msg" style={{ color: "red" }}>
                          {errorMsg.availableAmenities}
                        </p>
                      )}
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label>Bathroom (s) *</label>
                      <input
                        type="text"
                        value={bathrooms}
                        onChange={(e) => {
                          if (
                            !/^[0-9]{0,10}$/.test(e.target.value) ||
                            e.target.value == "e"
                          )
                            return;

                          {
                            updateState((prev) => ({
                              ...prev,
                              bathrooms: e.target.value,
                            }));
                          }
                        }}
                        placeholder="Enter Number of Bathroom."
                        class="form-control"
                      />

{errorMsg.bathrooms && !bathrooms && (
                        <p className="error-msg" style={{ color: "red" }}>
                          {errorMsg.bathrooms}
                        </p>
                      )}
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label>Balcony (s) *</label>
                      <input
                        type="text"
                        value={balcony}
                        placeholder="Enter Number of balcony."
                        onChange={(e) => {
                          if (
                            !/^[0-9]{0,10}$/.test(e.target.value) ||
                            e.target.value == "e"
                          )
                            return;

                          {
                            updateState((prev) => ({
                              ...prev,
                              balcony: e.target.value,
                            }));
                          }
                        }}
                        class="form-control"
                      />

{errorMsg.balcony && !balcony && (
                        <p className="error-msg" style={{ color: "red" }}>
                          {errorMsg.balcony}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="Buttons">
            <Link to="/flat-property3" class="PreviousBtn">
              Previous
            </Link>
            <a onClick={handleSubmitFlat} class="SaveContinueBtn">
              Save &amp; Continue
            </a>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default FlatProperty4;
