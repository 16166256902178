module.exports = {
    // Production url
    url : "https://pgdekhoindia.com:4500/api/v1/",
    //development url : "https://pgdekhoindia.com:8083/api/v1/",

    // s3Keys : {
    //     bucketName: "auuction",
    //     dirName: "pgDekhooAdmin",
    //     region: "us-east-1",
    //     accessKeyId: "AKIAU4GHOSL2BYOEG7UV",
    //     secretAccessKey: "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",
    //     }


        s3Keys : {
            bucketName: "pgdekhoprod",
            dirName: "pgDekhoWeb",
            region: "ap-south-1",
            accessKeyId: "AKIA4T4OCC6BXZGVBSX5",
            secretAccessKey: "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",
            }
    

}