import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import { createFlatApi } from "../reduxToolKit/slices/flatSlice.js/addFlatSlice";
import { useDispatch, useSelector } from "react-redux";
import { fetchFlatDetails } from "../reduxToolKit/slices/flatSlice.js/flatDetailsSilce";

const initialState = {
  apartmentType: "",
  apartmentName: "",
  BHKType: "",
  floor: "",
  totalFloor: "",
  propertyAge: "",
  facing: "",
  builtUpArea: "",

  errorMsg: {},
};

const FlatProperte1 = () => {
  const [iState, updateState] = useState(initialState);

  const { flatDetails } = useSelector((state) => state.flatData);


  const navigate = useNavigate();
  const dispatch = useDispatch()

  const {
    apartmentType,
    apartmentName,
    BHKType,
    floor,
    totalFloor,
    propertyAge,
    facing,
    builtUpArea,

    errorMsg,
  } = iState;

  const ApartmentTypeDropdown = [
    {
      label: "Independent Villa",
      value: "Independent Villa",
    },
    {
      label: "House Gated Society",
      value: "House Gated Society",
    },
  ];

  const BhkTypeDropdown = [
    {
      label: "1 RK",
      value: "1 RK",
    },
    {
      label: "1 BHK",
      value: "1 BHK",
    },
    {
      label: "2 BHK",
      value: "2 BHK",
    },
  ];
  const facingDropdown = [
    {
      label: "North",
      value: "North",
    },
    {
      label: "South",
      value: "South",
    },
    {
      label: "East",
      value: "East",
    },
    {
      label: "West",
      value: "West",
    },
    {
      label: "North-East",
      value: "North-East",
    },
    {
      label: "South-East",
      value: "South-East",
    },
    {
      label: "North-West",
      value: "North-West",
    },
    {
      label: "South-West",
      value: "South-West",
    },
  ];



  const floorDropdown = [
    {
      name: "Ground",
      isSelect: false,
      value:[0,0]
  },
  {
      name: "1 - 5 Floors",
      isSelect: false,
      value:[1,5]
  },
  {
      name: "6 - 10 Floors",
      isSelect: false,
      value:[6,10]
  },
  {
      name: "11 - 15 Floors",
      isSelect: false,
      value:[11,15]
  },
  {
      name: "16 - 20 Floors",
      isSelect: false,
      value:[16,20]
  },
  {
      name: "21 - 25 Floors",
      isSelect: false,
      value:[21,25]
  },
  {
      name: "26 - 30 Floors",
      isSelect: false,
      value:[26,30]
  }

  ];

  const handleChange = (e) => {
    const { name, value } = e.target;

    updateState({ ...iState, [name]: value });
  };

  const handleValidation = () => {
    let flag = true;
    let errorMsg = {};

    if (!apartmentType) {
      flag = false;
      errorMsg.apartmentType = " Apartment type field is required";
    }

    if (!apartmentName) {
      flag = false;
      errorMsg.apartmentName = "Apartment name field is required";
    }

    if (!BHKType) {
      flag = false;
      errorMsg.BHKType = "BHK type name field is required";
    }

    if (!floor) {
      flag = false;
      errorMsg.floor = "Floor  field is required";
    }

    if (!totalFloor) {
      flag = false;
      errorMsg.totalFloor = "Total floor field is required";
    }

    if (!propertyAge) {
      flag = false;
      errorMsg.propertyAge = "Property age field is required";
    }

    if (!facing) {
      flag = false;
      errorMsg.facing = "Facing age field is required";
    }

    if (!builtUpArea) {
      flag = false;
      errorMsg.builtUpArea = "Built up area field is required";
    }

    updateState({ ...iState, errorMsg });

    return flag;
  };


  const handleSubmitFlat = (e) => {
    e.preventDefault();

    let isValid = handleValidation();
    if (isValid) {
      



      let data= {  
        apartmentType,
        apartmentName,
        BHKType,
        floor,
        totalFloor,
        propertyAge,
        facing,
        builtUpArea,

      }
      dispatch(createFlatApi(data))
      .then((res) => {
        if (res.payload.success) {

            navigate("/flat-property2");

          toast.success(res.message);

        } else {
          toast.error(res.message);
        }
      })
      .catch((err) => {});
    }
  };

  useEffect(() => {
    dispatch(fetchFlatDetails());
  }, []);


  useEffect(() => {

    updateState({
      ...iState,

      apartmentType: flatDetails?.data?.[0]?.apartmentType
        ? flatDetails?.data?.[0]?.apartmentType
        : "",

        apartmentName: flatDetails?.data?.[0]?.apartmentName
        ? flatDetails?.data?.[0]?.apartmentName
        : "",

        
        BHKType: flatDetails?.data?.[0]?.BHKType
        ? flatDetails?.data?.[0]?.BHKType
        : "",

        floor: flatDetails?.data?.[0]?.floor
        ? flatDetails?.data?.[0]?.floor
        : "",

        totalFloor: flatDetails?.data?.[0]?.totalFloor
        ? flatDetails?.data?.[0]?.totalFloor
        : "",
        propertyAge: flatDetails?.data?.[0]?.propertyAge
        ? flatDetails?.data?.[0]?.propertyAge
        : "",

        facing: flatDetails?.data?.[0]?.facing
        ? flatDetails?.data?.[0]?.facing
        : "",
        builtUpArea: flatDetails?.data?.[0]?.builtUpArea
        ? flatDetails?.data?.[0]?.builtUpArea
        : "",

    });
  }, [flatDetails]);

  return (
    <>

<ToastContainer />

      <Header />

      <section>
        <div class="steps-details">
          <div class="StepBox">
            <ul>
              <li class="active">
                Step 1 <span>Property Details</span>{" "}
              </li>
              <li>
                Step 2 <span>Location Details</span>{" "}
              </li>
              <li>
                Step 3 <span>Rental Details</span>{" "}
              </li>
              <li>
                Step 4 <span>Amenities</span>{" "}
              </li>
              <li>
                Step 5 <span>Gallery</span>{" "}
              </li>
              <li>
                Step 6 <span>Schedule</span>{" "}
              </li>
            </ul>
          </div>
          <div class="flat-property-area">
            <form>
              <div class="ProvideTitleBox">
                <h3>Property Details</h3>
              </div>
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label>Apartment Type *</label>
                    <select
                      value={apartmentType}
                      name="apartmentType"
                      onChange={handleChange}
                      class="form-control"
                    >
                      <option>Select</option>
                      {ApartmentTypeDropdown?.map((type) => (
                        <option value={type.value}>{type.label}</option>
                      ))}
                    </select>
                    {errorMsg.apartmentType && !apartmentType && (
                      <p className="error-msg" style={{ color: "red" }}>
                        {errorMsg.apartmentType}
                      </p>
                    )}
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label>
                      Apartment Name (if Selected gated Community/Society) *
                    </label>
                    <input
                      value={apartmentName}
                      name="apartmentName"
                      onChange={handleChange}
                      className="form-control"
                      placeholder="Enter Apartment Name"
                    />

{errorMsg.apartmentName && !apartmentName && (
                      <p className="error-msg" style={{ color: "red" }}>
                        {errorMsg.apartmentName}
                      </p>
                    )}
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label>BHK Type *</label>
                    <select
                      value={BHKType}
                      name="BHKType"
                      onChange={handleChange}
                      class="form-control"
                    >
                      <option>Select</option>
                      {BhkTypeDropdown?.map((type) => (
                        <option value={type.value}>{type.label}</option>
                      ))}
                    </select>


                    {errorMsg.BHKType && !BHKType && (
                      <p className="error-msg" style={{ color: "red" }}>
                        {errorMsg.BHKType}
                      </p>
                    )}
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label>Floor *</label>
                    {/* <select
                      value={floor}
                      name="floor"
                      onChange={handleChange}
                      class="form-control"
                    >
                      <option>Select</option>
                      {floorDropdown?.map((type) => (
                        <option value={type.value}>{type.name}</option>
                      ))}
                    </select> */}

<input
                      value={floor}
                      name="floor"
                      onChange={(e)=>{
                        if (
                          !/^[0-9]{0,10}$/.test(e.target.value) ||
                          e.target.value == "e"
                        )
                          return;
                        {
                          updateState({...iState, floor:e.target.value})
                        }
                    }}                      className="form-control"
                      placeholder="Enter Total Floor"
                    />

{errorMsg.floor && !floor && (
                      <p className="error-msg" style={{ color: "red" }}>
                        {errorMsg.floor}
                      </p>
                    )}
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label>Total Floors *</label>
                    <input
                      value={totalFloor}
                      name="totalFloor"
                      onChange={(e)=>{
                        if (
                          !/^[0-9]{0,10}$/.test(e.target.value) ||
                          e.target.value == "e"
                        )
                          return;
                        {
                          updateState({...iState, totalFloor:e.target.value})
                        }
                    }}                      className="form-control"
                      placeholder="Enter Total Floor"
                    />

{errorMsg.totalFloor && !totalFloor && (
                      <p className="error-msg" style={{ color: "red" }}>
                        {errorMsg.totalFloor}
                      </p>
                    )}
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label>Property Age (in years)*</label>
                    <input
                      value={propertyAge}
                      name="propertyAge"
                      onChange={(e)=>{
                        if (
                          !/^[0-9]{0,10}$/.test(e.target.value) ||
                          e.target.value == "e"
                        )
                          return;
                        {
                          updateState({...iState, propertyAge:e.target.value})
                        }
                    }}                       className="form-control"
                      placeholder="Enter Property Age"
                    />

{errorMsg.propertyAge && !propertyAge && (
                      <p className="error-msg" style={{ color: "red" }}>
                        {errorMsg.propertyAge}
                      </p>
                    )}
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label>Facing *</label>
                    <select
                      value={facing}
                      name="facing"
                      onChange={handleChange}
                      class="form-control"
                    >
                      <option>Select</option>
                      {facingDropdown?.map((face) => (
                        <option value={face.value}>{face.label}</option>
                      ))}
                    </select>

                    {errorMsg.facing && !facing && (
                      <p className="error-msg" style={{ color: "red" }}>
                        {errorMsg.facing}
                      </p>
                    )}
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label>Built up area (sq.ft.) *</label>
                    <input
                      value={builtUpArea}
                      onChange={(e)=>{
                        if (
                          !/^[0-9]{0,10}$/.test(e.target.value) ||
                          e.target.value == "e"
                        )
                          return;
                        {
                          updateState({...iState, builtUpArea:e.target.value})
                        }
                    }}                       className="form-control"
                      placeholder="Enter Property Age"
                    />
                      {errorMsg.builtUpArea && !builtUpArea && (
                      <p className="error-msg" style={{ color: "red" }}>
                        {errorMsg.builtUpArea}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="Buttons">
            <Link to="/sell" class="PreviousBtn">
              Previous
            </Link>
            <a onClick={handleSubmitFlat} class="SaveContinueBtn">
              Save &amp; Continue
            </a>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default FlatProperte1;
