import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  const handleScroll = () => {
    window.scrollTo(0, 1400);
  };
  return (
    <footer>
      <div class="footer">
        <ul>
        <li>
            {/* Address: Magnum Global Park, <br />
            Sector 58, Gurugram, Haryana, <br />
            122011, India
            <br /> */}
            Email: info@pgdekhoindia.in
          </li>
        </ul>
        <ul>
          
          <li>
            <Link to="/about-us">About Us</Link>
          </li>
          <li>
            <Link to="/terms-condition">Terms & Conditions</Link>
          </li>
          <li>
            <Link to="/privacy-policy">Privacy Policy</Link>
          </li>
          <li>
            <a style={{ cursor: "pointer" }} onClick={() => handleScroll()}>
              Testimonials
            </a>
          </li>
          <li>
            <Link to="/faq">FAQs</Link>
          </li>
          {/* <li>
                <a href="#">Contact Us</a>
            </li> */}
        </ul>
        <figure>
          <img src={require("../assets/images/android.png")} alt="" style={{width:"100px"}}/>
          <img src={require("../assets/images/ios.png")} alt="" style={{width:"100px"}}/>
        </figure>
      </div>
      <div class="last-footer">
        <figcaption>
          <p>
            Copyright @ 2024 | All Rights Reserved by Roomer Technologies Pvt.
            Ltd.
          </p>
        </figcaption>
        <ul>
          <li>
            <a>
              <img src={require("../assets/images/facbook.png")} alt="" />
            </a>
          </li>
          <li>
            <a>
              <img src={require("../assets/images/insta.png")} alt="" />
            </a>
          </li>
          <li>
            <a>
              <img src={require("../assets/images/tweter.png")} alt="" />
            </a>
          </li>

          <li>
            <a>
              <img src={require("../assets/images/Linkedin.png")} alt="" />
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
