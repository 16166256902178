import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Link, useNavigate } from "react-router-dom";
import Map from "./map";
import AutoPlace from "./AutoPlace";
import { useDispatch, useSelector } from "react-redux";
import { fetchPgDetails } from "../reduxToolKit/slices/pgSlices/pgDetailsSlice";
import MapComponent from "./MapComponent";
import { toast } from "react-toastify";
import { createPgApi } from "../reduxToolKit/slices/pgSlices/addPgAddSlice";

const initialState = {
  lat: "",
  long: "",

  city: "",
  locality: "",
  street: "",
  address: "",
};

const EditProperty2 = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pgDetails } = useSelector((state) => state.pgData);
  const [iState, updateState] = useState(initialState);
  const { address, lat, long, city, street, locality } = iState;

  useEffect(() => {
    dispatch(fetchPgDetails());
  }, []);

  useEffect(() => {
    updateState({
      ...iState,

      city: pgDetails?.data?.[0]?.city,
      locality: pgDetails?.data?.[0]?.locality,
      street: pgDetails?.data?.[0]?.street,
      address: pgDetails?.data?.[0]?.address,
      lat:pgDetails?.data?.[0]?.location?.coordinates?.[0],
      long:pgDetails?.data?.[0]?.location?.coordinates?.[1],
    });
  }, [pgDetails]);

  const handleSubmitFlat = (e) => {
    e.preventDefault();

    let isValid = true
    if (isValid) {
      let data= {  
        locality,
        street,
        city,
        address,
        // location: {
        //   type: "Point",
        //   coordinates: [ long,lat],
        // }
      }
      dispatch(createPgApi(data))
      .then((res) => {
        if (res.payload.success) {

            navigate("/edit-property-3");

          toast.success(res.message);

        } else {
          toast.error(res.message);
        }
      })
      .catch((err) => {});
    }
  };
  return (
    <>
      <Header />

      <section>
        <div class="steps-details">
          <div class="StepBox">
            <ul>
              <li class="Previous">
                Step 1 <span>Room Details</span>{" "}
                <h6 class="Complete">
                  Complete{" "}
                  <span>
                    <i class="fa-solid fa-check"></i>
                  </span>{" "}
                </h6>{" "}
              </li>
              <li class="active">
                Step 2 <span>Location Details</span>{" "}
              </li>
              <li>
                Step 3 <span>PG Details</span>{" "}
              </li>
              <li>
                Step 4 <span>Amenities Details</span>{" "}
              </li>
              <li>
                Step 5 <span>Gallery</span>{" "}
              </li>
              <li>
                Step 6 <span>Schedule</span>{" "}
              </li>
            </ul>
          </div>
          <div class="EditPropertyBox">
            <h3 class="Title">Location Details</h3>
            <div class="EditPropertyForm">
              <form>
                <div class="row">
                  <div class="col-lg-4">
                    <div class="form-group">
                      <h6>
                        City <span class="required">*</span>
                      </h6>
                      <input
                        type="text"
                        value={city}
                        class="form-control"
                        placeholder="Enter City Name"
                      />
                    </div>

                    <div class="form-group">
                      <h6>Complete Address</h6>

                      <AutoPlace
                        updateState={updateState}
                        iState={iState}
                        address={address}
                      />
                      {/* <input type="text" class="form-control" placeholder="Enter Address" /> */}
                    </div>
                  </div>
                  <div class="col-lg-4 offset-2">
                    <div class="form-group">
                      <h6>Locality *</h6>
                      <input
                        type="text"
                        class="form-control"
                        value={locality}
                        placeholder=""
                        required
                      />
                    </div>
                    <div class="form-group">
                      <h6>Landmark/Street</h6>
                      <input
                        type="text"
                        class="form-control"
                        value={street}
                        placeholder="Enter Landmark Name"
                        onChange={(e)=>updateState({...iState,street:e.target.value})}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <h3 class="Title">
              {" "}
              <span>
                <img src={require("../assets/images/location.png")} />
              </span>{" "}
              Mark Locality on Map
            </h3>
            <p class="Para">
              Set property location by using search box and move the map
            </p>
            {/* <Map iState={iState} updateState={updateState} /> */}
            <MapComponent lat={iState?.lat} lng={iState.long}/>
          </div>
          <div class="Buttons">
            <Link to="/edit-property-1" class="PreviousBtn">
              Previous
            </Link>
            <a onClick={handleSubmitFlat}class="SaveContinueBtn">
              Save &amp; Continue
            </a>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default EditProperty2;
