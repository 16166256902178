import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import doubleBed from "../assets/images/doubleBed.png";
import tableChair from "../assets/images/tableChair.png";
import cupboard from "../assets/images/cupBoard.png";
import wifiImage from "../assets/images/wifi.png";
import firstAid from "../assets/images/first-aid.png";
import Button from "react-bootstrap-button-loader";

import { FaTv } from "react-icons/fa";
import { FaBed } from "react-icons/fa";
import { TbAirConditioning } from "react-icons/tb";
import { GiSlicedBread } from "react-icons/gi";
import { PiPicnicTableBold } from "react-icons/pi";
import { BiSolidBlanket } from "react-icons/bi";
import { FaMattressPillow } from "react-icons/fa6";
import { LuBedDouble } from "react-icons/lu";
import { MdOutlineChair } from "react-icons/md";
import { GiThermometerHot } from "react-icons/gi";
import { BsFillRouterFill } from "react-icons/bs";
import { GiCooler } from "react-icons/gi";
import { BiSolidFridge } from "react-icons/bi";
import { GiEscalator } from "react-icons/gi";
import { SiAdguard } from "react-icons/si";
import { CgGym } from "react-icons/cg";
import { RiFirstAidKitFill } from "react-icons/ri";
import { FaSquareParking } from "react-icons/fa6";
import { LuMicrowave } from "react-icons/lu";
import { GrUserManager } from "react-icons/gr";

import { useDispatch, useSelector } from "react-redux";
import { fetchPgDetails } from "../reduxToolKit/slices/pgSlices/pgDetailsSlice";
import { createPgApi } from "../reduxToolKit/slices/pgSlices/addPgAddSlice";
import { GiPowerGenerator } from "react-icons/gi";

const initialState = {
  isLaundryService: false,
  laundryService: {
    title: "Weekdays", //["Everyday", "Weekdays", "Weekends"]
    days: "Mon-Fri", //["Mon-Sun", "Mon-Fri", "Sat-Sun"]
  },
  waterFacility: true,
  availableAmenities: [],
  roomAmenities:[],
  parking: true,
  vehicleType: "",
  roomCleaning: true,
  errorMsg: {},
};
const EditProperty4 = () => {
  const [isLoading, setLoading] = useState(false);

  const [iState, updateState] = useState(initialState);
  const { pgDetails } = useSelector((state) => state.pgData);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const amenitiesArray = [
    {
      name: "Power-Back Up",
      img:<GiPowerGenerator style={{color:"#FD701E",fontSize:"22px"}}/>
  },
  {
      name: "Lift",
       img:<GiEscalator style={{color:"#FD701E",fontSize:"22px"}}/>,
      isSelected: tableChair
  },
  {
      name: "Wi-fi",
       img:<BsFillRouterFill style={{color:"#FD701E",fontSize:"22px"}}/>,
      isSelected: wifiImage
  },

  {
      name: "Water Cooler",
       img:<GiCooler style={{color:"#FD701E",fontSize:"22px"}}/>,
      isSelected: doubleBed
  },
  {
      name: "Fridge",
       img:<BiSolidFridge style={{color:"#FD701E",fontSize:"22px"}}/>,
      isSelected: doubleBed
  },
  {
      name: "Microwave",
       img:<LuMicrowave style={{color:"#FD701E",fontSize:"22px"}}/>,
      isSelected: doubleBed
  },
  {
      name: "First Aid Kit",
       img:<RiFirstAidKitFill style={{color:"#FD701E",fontSize:"22px"}}/>,
      isSelected: firstAid
  },
  {
      name: "Warden/ Manager",
       img:<GrUserManager style={{color:"#FD701E",fontSize:"22px"}}/>,
      isSelected: doubleBed
  },
  {
      name: "Security Guard",
       img:<SiAdguard style={{color:"#FD701E",fontSize:"22px"}}/>,
      isSelected: doubleBed
  },
  // {
  //     name: "Parking",
  //      img:<FaSquareParking style={{color:"#FD701E",fontSize:"22px"}}/>,
  //     isSelected: doubleBed
  // },
  {
      name: "Gym",
       img:<CgGym style={{color:"#FD701E",fontSize:"22px"}}/>,
      isSelected: cupboard
  }
  ];
  const roomAmenitiesArray = [
  { name: "Television", img: <FaTv style={{color:"#FD701E",fontSize:"22px"}}/> },
  { name: "Single Bed", img: <FaBed style={{color:"#FD701E",fontSize:"22px"}}/> },
  { name: "AC/ Heating", img: <TbAirConditioning style={{color:"#FD701E",fontSize:"22px"}}/> },
  { name: "Mattress + Pillow", img: <FaMattressPillow style={{color:"#FD701E",fontSize:"22px"}}/> },
  { name: "Table + Chair", img: <MdOutlineChair style={{color:"#FD701E",fontSize:"22px"}}/> },
  { name: "Blanket/Quilt", img: <BiSolidBlanket style={{color:"#FD701E",fontSize:"22px"}}/> },
  { name: "Side Table", img: <PiPicnicTableBold style={{color:"#FD701E",fontSize:"22px"}}/> },
  { name: "Queen Bed", img: <LuBedDouble style={{color:"#FD701E",fontSize:"22px"}}/> },
  { name: "Hot Water", img: <GiThermometerHot style={{color:"#FD701E",fontSize:"22px"}}/> },
  { name: "Cupboard", img: <GiSlicedBread style={{color:"#FD701E",fontSize:"22px"}}/> },
];



  

  const {
    isLaundryService,
    laundryService,
    availableAmenities,
    parking,
    vehicleType,
    waterFacility,
    roomCleaning,
    errorMsg,
    roomAmenities,
  } = iState;

  const handleRadio = (e, setter, prop) => {
    updateState({ ...iState, [setter]: prop });
  };

  const handleCheckbox = (e, getter, prop) => {
    const { checked, name } = e.target;

    if (checked) {
      updateState({ ...iState, [name]: [...getter, prop] });
    } else {
      let tempArray = getter;
      let index = tempArray.findIndex((item) => item == prop);
      tempArray.splice(index, 1);

      updateState({ ...iState, [name]: tempArray });
    }
  };

  useEffect(() => {
    dispatch(fetchPgDetails());
  }, []);

  const handleValidation = () => {
    let flag = true;
    let errorMsg = {};

    if (availableAmenities?.length == 0) {
      flag = false;
      errorMsg.availableAmenities = " Amenities  field is required";
    }

    if (!vehicleType && iState.parking) {
      flag = false;
      errorMsg.vehicleType = "Vehicle type field is required";
    }

    updateState({ ...iState, errorMsg });

    return flag;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let isValid = handleValidation();
    if (isValid) {
      setLoading(true)
      let data = {
        isLaundryService,
        laundryService,
        availableAmenities,
        parking,
        vehicleType,
        waterFacility,
        roomCleaning,
        roomAmenities
      };

      dispatch(createPgApi(data))
        .then((res) => {
          if (res.payload.success) {

            setLoading(false)
              navigate("/edit-property-5");

              
            toast.success(res.message);
          } else {
            toast.error(res?.payload?.response?.data?.message);
          }
        })
        .catch((err) => {});
    }
  };

  useEffect(() => {
    updateState({
      ...iState,

      waterFacility: pgDetails?.data?.[0]?.waterFacility
        ? pgDetails?.data?.[0]?.waterFacility
        : false,
      availableAmenities: pgDetails?.data?.[0]?.availableAmenities
        ? pgDetails?.data?.[0]?.availableAmenities
        : [],
      parking: pgDetails?.data?.[0]?.parking
        ? pgDetails?.data?.[0]?.parking
        : true,
      vehicleType: pgDetails?.data?.[0]?.vehicleType
        ? pgDetails?.data?.[0]?.vehicleType
        : "",
      roomCleaning: pgDetails?.data?.[0]?.roomCleaning
        ? pgDetails?.data?.[0]?.roomCleaning
        : false,

      isLaundryService: pgDetails?.data?.[0]?.isLaundryService
        ? pgDetails?.data?.[0]?.isLaundryService
        : false,

        roomAmenities: pgDetails?.data?.[0]?.roomAmenities
        ? pgDetails?.data?.[0]?.roomAmenities
        : [],
    });
  }, [pgDetails]);

  useEffect(() => {
    dispatch(fetchPgDetails());
    window.scroll(0,0)
  }, []);


  return (
    <>
      <ToastContainer />

      <Header />
      <section>
        <div class="steps-details">
          <div class="StepBox">
            <ul>
              <li class="Previous">
                Step 1 <span>Room Details</span>
                <h6 class="Complete">
                  Complete{" "}
                  <span>
                    <i class="fa-solid fa-check"></i>
                  </span>
                </h6>
              </li>
              <li class="Previous">
                Step 2 <span>Location Details</span>
                <h6 class="Complete">
                  Complete{" "}
                  <span>
                    <i class="fa-solid fa-check"></i>
                  </span>{" "}
                </h6>{" "}
              </li>
              <li class="Previous">
                Step 3 <span>PG Details</span>{" "}
              </li>
              <li class="active">
                Step 4 <span>Amenities Details</span>{" "}
              </li>
              <li>
                Step 5 <span>Gallery</span>{" "}
              </li>
              <li>
                Step 6 <span>Schedule</span>{" "}
              </li>
            </ul>
          </div>
          <div class="EditPropertyBox">
            <h3 class="Title">Available Services</h3>
            <div class="EditPropertyForm">
              <form>
                <div class="row">
                  <div class="col-lg-4">
                    <div class="form-group">
                      <h6>Laundry Service</h6>
                      <ul>
                        <li>
                          <label class="Radio">
                            {" "}
                            Yes
                            <input
                              checked={isLaundryService}
                              onChange={(e) =>
                                handleRadio(e, "isLaundryService", true)
                              }
                              type="radio"
                              name="isLaundryService"
                            />
                            <span class="checkmark"></span>
                          </label>
                        </li>
                        <li>
                          <label class="Radio">
                            {" "}
                            No
                            <input
                              checked={!isLaundryService}
                              onChange={(e) =>
                                handleRadio(e, "isLaundryService", false)
                              }
                              type="radio"
                              name="isLaundryService"
                            />
                            <span class="checkmark"></span>
                          </label>
                        </li>
                      </ul>
                    </div>
                    <div class="form-group">
                      <h6>RO Water Facility</h6>
                      <ul>
                        <li>
                          <label class="Radio">
                            {" "}
                            Yes
                            <input
                              checked={waterFacility}
                              type="radio"
                              onChange={(e) =>
                                handleRadio(e, "waterFacility", true)
                              }
                              name="waterFacility"
                            />
                            <span class="checkmark"></span>
                          </label>
                        </li>
                        <li>
                          <label class="Radio">
                            {" "}
                            No
                            <input
                              checked={!waterFacility}
                              type="radio"
                              onChange={(e) =>
                                handleRadio(e, "waterFacility", false)
                              }
                              name="waterFacility"
                            />
                            <span class="checkmark"></span>
                          </label>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-lg-4 offset-2">
                    <div class="form-group">
                      <h6>Room Cleaning</h6>
                      <ul>
                        <li>
                          <label class="Radio">
                            {" "}
                            Yes
                            <input
                              checked={roomCleaning}
                              onChange={(e) =>
                                handleRadio(e, "roomCleaning", true)
                              }
                              type="radio"
                              name="roomCleaning"
                            />
                            <span class="checkmark"></span>
                          </label>
                        </li>
                        <li>
                          <label class="Radio">
                            {" "}
                            No
                            <input
                              checked={!roomCleaning}
                              onChange={(e) =>
                                handleRadio(e, "roomCleaning", false)
                              }
                              type="radio"
                              name="roomCleaning"
                            />
                            <span class="checkmark"></span>
                          </label>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="form-group">
                      <h6>Available Amenities of PG</h6>
                      <ul class="Extra">
                        {amenitiesArray?.map((item) => (
                          <li>
                            <label class="CheckBox">
                              {" "}
                               <span>{item.img}<br /> {item?.name}</span>
                              <input
                                checked={availableAmenities?.includes(
                                  item?.name
                                )}
                                type="checkbox"
                                name="availableAmenities"
                                onChange={(e) =>
                                  handleCheckbox(
                                    e,
                                    availableAmenities,
                                    item?.name
                                  )
                                }
                              />
                              <span class="checkmark"></span>
                            </label>
                          </li>
                        ))}
                      </ul>

                      {errorMsg.availableAmenities &&
                        availableAmenities?.length == 0 && (
                          <p className="error-msg" style={{ color: "red" }}>
                            {errorMsg.availableAmenities}
                          </p>
                        )}
                    </div>
                  </div>

                  <div class="col-lg-12">
                    <div class="form-group">
                      <h6>Room Amenities of PG</h6>
                      <ul class="Extra">
                        {roomAmenitiesArray?.map((item) => (
                          <li>
                            <label class="CheckBox">
                              {" "}
                              <span>{item.img}</span> <br /> {item?.name}
                              <input
                                checked={roomAmenities?.includes(
                                  item?.name
                                )}
                                type="checkbox"
                                name="roomAmenities"
                                onChange={(e) =>
                                  handleCheckbox(
                                    e,
                                    roomAmenities,
                                    item?.name
                                  )
                                }
                              />
                              <span class="checkmark"></span>
                            </label>
                          </li>
                        ))}
                      </ul>

                      {errorMsg.availableAmenities &&
                        availableAmenities?.length == 0 && (
                          <p className="error-msg" style={{ color: "red" }}>
                            {errorMsg.availableAmenities}
                          </p>
                        )}
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <h6>
                        Parking <span class="required">*</span>{" "}
                      </h6>
                      <ul>
                        <li>
                          <label class="Radio">
                            {" "}
                            Yes
                            <input
                              checked={parking}
                              onChange={(e) => handleRadio(e, "parking", true)}
                              type="radio"
                              name="boy"
                            />
                            <span class="checkmark"></span>
                          </label>
                        </li>
                        <li>
                          <label class="Radio">
                            {" "}
                            No
                            <input
                              checked={!parking}
                              onChange={(e) => handleRadio(e, "parking", false)}
                              type="radio"
                              name="boy"
                            />
                            <span class="checkmark"></span>
                          </label>
                        </li>
                      </ul>
                    </div>
                    {parking &&
                    <div class="form-group">
                      <h6>Vehicle Type</h6>
                      <ul>
                        <li>
                          <label class="CheckBox">
                            {" "}
                            Car
                            <input
                              checked={vehicleType == "Car" ? true : false}
                              type="radio"
                              name="vehicleType"
                              onChange={(e) => {
                                updateState((prev) => ({
                                  ...prev,
                                  vehicleType: "Car",
                                }));
                              }}
                            />
                            <span class="checkmark"></span>
                          </label>
                        </li>
                        <li>
                          <label class="CheckBox">
                            {" "}
                            Bike
                            <input
                              checked={vehicleType == "Bike" ? true : false}
                              type="radio"
                              name="vehicleType"
                              onChange={(e) => {
                                updateState((prev) => ({
                                  ...prev,
                                  vehicleType: "Bike",
                                }));
                              }}
                            />
                            <span class="checkmark"></span>
                          </label>
                        </li>
                        <li>
                          <label class="CheckBox">
                            {" "}
                            Both
                            <input
                              checked={vehicleType == "Both" ? true : false}
                              type="radio"
                              name="vehicleType"
                              onChange={(e) => {
                                updateState((prev) => ({
                                  ...prev,
                                  vehicleType: "Both",
                                }));
                              }}
                            />
                            <span class="checkmark"></span>
                          </label>
                        </li>
                      </ul>

                      {errorMsg.vehicleType && !vehicleType && (
                        <p className="error-msg" style={{ color: "red" }}>
                          {errorMsg.vehicleType}
                        </p>
                      )}
                    </div>}
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="Buttons">
            <Link to="/edit-property-3" class="PreviousBtn">
              Previous
            </Link>
            <Button
                  loading={isLoading} onClick={handleSubmit} class="SaveContinueBtn">
              Save &amp; Continue
            </Button>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default EditProperty4;
